import Analytics from '@segment/analytics-node';
import { getSession } from 'next-auth/react';
import { SG_EVENT_NAMES } from './eventNames';

export async function trackPageViewed(pageName, props) {
   if (typeof window !== 'undefined') {
      window?.analytics?.page(pageName, props);
   }
}

export async function trackEvent(eventName, props) {
   // check if analytics and gtag is loaded
   // if not, return and try again after 1 second
   if (!window.analytics || !window.gtag) {
      setTimeout(() => {
         trackEvent(eventName, props);
      }, 1000);
      return;
   }

   if (typeof window !== 'undefined' || !window) {

      // Segment identify user
      const session = await getSession();
      if (session?.user?.id) {
         window.analytics.identify(session?.user?.id, {
            email: session?.user?.email,
            name: session?.user?.name,
         });
      }

      window?.analytics?.track(eventName, props);

      // Google Analytics 4 event
      if (!window.gtag) return;
      window?.gtag('event', eventName, {
         event_label: eventName,
         event_category: 'app_event',
         ...props,
         event_callback: function () {
         },
      });
   }

   // send conversion to Getreditus if user sign up
   if (eventName === SG_EVENT_NAMES.USER_SIGN_UP) {
      SendConversionToGetreditus(props.email);
   }
}

export async function trackEventServer(eventName, props) {
   const analytics = new Analytics({ writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY });
   analytics.track({
      userId: props.userId,
      email: props.email,
      event: eventName,
      properties: props,
   });
}

export async function identifyUser(userId, props) {
   const session = await getSession();
   if (!userId) {
      userId = session?.user?.id;
   }

   if (!props) {
      props = {
         email: session?.user?.email,
         name: session?.user?.name,
         username: session?.user?.username,
         image: session?.user?.image,
      };
   }

   if (typeof window !== 'undefined') {
      window.analytics.identify(userId, props);
   }
}

function SendConversionToGetreditus(email) {
   // check window
   if (typeof window !== 'undefined') {
      window.gr('track', 'conversion', { email: email });
   }
}
