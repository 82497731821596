import { ThemeProvider } from '@mui/material';
import * as segment from '@segment/snippet';
import { SessionProvider } from 'next-auth/react';
import '../styles/globals.css';
import Theme from '../styles/muiTheme.js';

// router loading
import { trackPageViewed } from '@/utils/tools/segment/tracking';
import { useRouter } from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useEffect } from 'react';
import { SWRConfig } from 'swr';

export default function App({ Component, pageProps }) {
   const router = useRouter();
   const getLayout = Component.getLayout || ((page) => page);

   NProgress.configure({ showSpinner: false });

   useEffect(() => {
      const handler = (url, { shallow }) => {
         if (shallow) {
            trackPageViewed(url);
         }
         if (!shallow) NProgress.start();
      };
      router.events.on('routeChangeStart', handler);
      return () => router.events.off('routeChangeStart', handler);
   }, [router.events]);

   useEffect(() => {
      const handler = (url, options) => {
         NProgress.done();
         trackPageViewed(url);
      };

      router.events.on('routeChangeComplete', handler);
      return () => router.events.off('routeChangeComplete', handler);
   }, [router.events]);

   function loadSegment() {
      const options = {
         apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
         page: false,
      };
      if (process.env.NODE_ENV === 'production') return segment.min(options);
      else return segment.max(options);
   }

   return (
      <>
         <Script strategy="lazyOnload" dangerouslySetInnerHTML={{ __html: loadSegment() }} id="segment" />
         <SWRConfig
            value={{
               fetcher: (resource, init) =>
                  fetch(resource, init).then((res) => {
                     if (!res.ok) {
                        const error = new Error('An error occurred while fetching the data.');
                        // get error message from body or default to response status
                        error.info = res.json();
                        error.status = res.status;
                        throw error;
                     }
                     return res.json();
                  }),
               // refreshInterval: 5000,
            }}
         >
            <SessionProvider>
               <ThemeProvider theme={Theme}>{getLayout(<Component {...pageProps} />)}</ThemeProvider>
            </SessionProvider>
         </SWRConfig>
      </>
   );
}
